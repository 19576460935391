import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container
} from 'reactstrap';
import Logo from './Logo';
import { Link } from "react-router-dom"

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    const toggleDropdown = (param) => setIsDropdown(param || !isDropdown);

    const getActiveClassName = (url) => {
        if (`${window.location.pathname}${window.location.hash}` == url) {
            return "active"
        } else {
            return ''
        }
    }

    const changeNavActive = (param)=>{
        // console.log(param)

    }

    window.addEventListener("scroll", changeNavActive)
    return (
        <Navbar color="white" fixed="top" light expand="md">
            <Container>
                <Link className="nav-brand" to="/">
                    <Logo style={{ height: "50px" }} />
                </Link>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem className={`${getActiveClassName('/')}`}>
                            <Link className="nav-link" to="/">Home</Link>
                        </NavItem>
                        <Dropdown
                            nav
                            inNavbar
                            isOpen={isDropdown}
                            onMouseEnter={() => toggleDropdown(true)}
                            onMouseLeave={() => toggleDropdown(false)}
                            toggle={toggleDropdown}
                        >
                            <DropdownToggle nav caret>
                                Solutions
              </DropdownToggle>
                            <DropdownMenu>
                                <div className="d-flex">
                                    <DropdownItem href="/#easybox" className={`${getActiveClassName('/#easybox')} d-flex align-items-center`}>
                                        <img className='mr-3' loading='lazy' alt='easy box icon' src={require('../../assets/svgs/EasyBox-Icon.svg').default} width='20'/>
                                        Easy Box
                </DropdownItem>
                                    <DropdownItem href="/#easycommerce" className={`${getActiveClassName('/#easycommerce')} d-flex align-items-center`}>
                                        <img className='mr-3' loading='lazy' alt='easy finance icon' src={require('../../assets/svgs/EasyCommerce-Icon.svg').default} width='20'/>
                                        EasyCommerce
                </DropdownItem>
                                </div>

                                <Link divider />
                                <div className="d-flex">
                                    <DropdownItem href="/#easyfinance" className={`${getActiveClassName('/#easyfinance')} d-flex align-items-center`}>
                                        <img className='mr-3' loading='lazy' alt='easy finance icon' src={require('../../assets/svgs/EasyFinance-Icon.svg').default} width='20'/>
                                        EasyFinance
                </DropdownItem>
                                    <DropdownItem href="/#easyswitch" className={`${getActiveClassName('/#easyswitch')} `}>
                                    <   img className='mr-3' loading='lazy' alt='easy switch icon' src={require('../../assets/svgs/EasySwitch-Icon.svg').default} width='20'/>
                                        EasySwitch
                </DropdownItem>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                        <NavItem className={`${getActiveClassName('/#trackrecord')}`}>
                            <NavLink  href="/#trackrecord">Track Record</NavLink>
                        </NavItem>
                        <NavItem className={`${getActiveClassName('/contact')}`}>
                            <a className="nav-link" href="/contact">Contact</a>
                        </NavItem>
                        <NavItem className={`${getActiveClassName('/blog')}`}>
                            <a className="nav-link" href={`${process.env.REACT_APP_BASE_URL}`} target="blank">Blog</a>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;