import React from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'

export default function Locations() {
    return (
        <Container className="my-5">
                <Row>
                    <Col sm="12">
                        <h1>Contact us</h1>
                        <p className="main-p">We’d love to talk about how we can be of help </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Card className="text-left">
                            <CardBody>
                                <img src={require('../../../assets/svgs/icons/Briefcase.svg').default} width="40" className="mb-2"/>
                                <p className="text-muted ">14, Yeye Olofin Street, Lekki Phase 1, Lagos, Nigeria</p>
                                <p className="text-muted">info@innovectives.com</p>
                                <p className="text-primary directions">Get Directions</p>
                                <hr className="styled-hr"/>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-left my-4">
                            <CardBody>
                                <img loading='lazy' src={require('../../../assets/svgs/icons/Briefcase.svg').default} width="40" className="mb-2"/>
                                <p className="text-muted">New York</p>
                                <p className="text-muted mb-0">25 Bond Street, Brooklyn,<br/> NY 11201</p>
                                <a href='mailto:info@innovectives.group' className='text-muted'> info@innovectives.group</a>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-left my-4">
                            <CardBody>
                                <img loading='lazy' src={require('../../../assets/svgs/icons/Briefcase.svg').default} width="40" className="mb-2"/>
                                <p className="text-muted">Amsterdam</p>
                                <p className="text-muted mb-0">Soerlaan 18, 1185 JJ Amstelveen, Netherlands</p>
                                <a className='text-muted' href="mailto:info@indexdigital.nl">info@indexdigital.nl</a>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
        </Container>
    )
}
