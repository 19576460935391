import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

function EasySwitch(){
    return(
        <Container className='text-center text-md-left mt-5'>
            <Row>
                <Col md='6' className="order-2 order-md-1">
                    <div className="pr-md-4">
                        <img loading='lazy' alt='easy switch background' src={require('../../../assets/images/EasySwitch-BG.png').default} className='img-fluid my-n2'/>
                    </div>
                </Col>
                <Col md="6" className="order-1 text-deep-blue order-md-2">
                    <div className='pl-md-4'>
                        <div className="py-4 pl-md-4">
                            <img loading='lazy' src={require("../../../assets/svgs/EasySwitch-Logo.svg").default} className="mb-5" alt='easy switch logo' />
                            <p className="mb-3">Digital-first transaction authorization, processing and settlement engine including the development of Payment Terminal Applications.</p>
                            <p className="mt-0"> EasySwitch is powered by BPC SmartVista–a leading global payment technology provider. </p>

                            <Button outline color="primary">
                            Learn More
                            </Button>

                        </div>
                    </div>
                    
                </Col>
            </Row>
        </Container>
    )
}
export default EasySwitch
