import React from 'react'
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap'
import Logo from '../../../components/common/Logo'
import WorldContentIcon from '../../../assets/svgs/WorldContent'

export default function Expanding() {
    const addresses = [{location: 'Lagos', address: '14 Yeye Olofin Street, Lekki Phase 1, Lagos, Nigeria'}, {location: 'Amsterdam', address: 'Soerlaan 18, 1185 JJ Amstelveen, Netherlands'}, {location: 'New York', address: '25 Bond Street, Brooklyn, NY 11201'}]
    return (
        <Container className='text-center text-md-left my-5'>
            <Row>
                <Col sm="12" className="text-center mb-5">
                    <Logo width="150" />
                    <h4 className="text-dark-blue my-3">We are constantly expanding</h4>
                </Col>
                <Col md="7">
                    {/* <img loading='lazy' src={require('../../../assets/svgs/World-Content.svg').default} className="img-fluid  d-none d-md-block" /> */}
                    <div className="d-none d-md-block">
                    <WorldContentIcon />
                    </div>
                    <div className="d-block d-md-none">
                        {addresses.map((address, i)=>(
                            <Card key={i} className="text-left my-2">
                                <CardBody className="text-deep-blue">
                                    <h4 className="my-2">{address.location}</h4>
                                    <p>{address.address}</p>
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                </Col>

                <Col md="5">
                    <div className="dotted-border">
                        <p className="text-deep-blue">
                            At Innovectives Digital Experience (inDex), we thrive on strategic partnerships and relentless innovation, towards delivering a top-tier digital banking and commerce experience to our stakeholders.
                            </p>
                        <p className="text-deep-blue">
                            We welcome you to join us!
                            </p>
                        <Button color="primary" className="my-5">
                            Get In Touch
                            </Button>

                        <div className="socials mx-auto mt-3">
                            <div class="row">
                                <div className="col">
                                    <a href="https://wa.me/2349060007534"><i class="text-deep-blue  icofont-ui-messaging"></i></a>
                                </div>
                                <div className="col">
                                    <a href="#"><i class="text-deep-blue  icofont-ui-call"></i></a>
                                </div>
                                <div className="col">
                                    <a href="#"><i class="text-deep-blue  icofont-linkedin"></i></a>
                                </div>
                                <div className="col">

                                    <a href="#"><i class="text-deep-blue  icofont-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
