import React, { useState, useRef, useEffect } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Container, Row, Col, Card, Button, CardTitle, CardText, Containerfluid } from 'reactstrap'
import classnames from 'classnames';
import CarouselSlide from 'react-multi-carousel';

export default function TrackRecord() {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const tabHeaders = [
        { header: "SMEs", index: '1' },
        { header: "Individuals", index: '2' },
        { header: "Corporates", index: '3' },
        { header: "Government", index: '4' },
    ]
    /*const makeTimer = () =>{
        let value = [1, 2, 3, 4]
        value.forEach((value, i) =>{
            setTimeout(() =>{

                setActiveTab(value)
            }, i * 3000)
        })
    }

    makeTimer()*/

    
    const MakeTimer = () => {
        const activeTabRef = useRef(activeTab)
        useEffect(() => {
            tabHeaders.forEach((value, i) => {
                setInterval(() => {
                    activeTabRef.current = value.index
                    setActiveTab(activeTabRef.current)
                }, 5000)
            })
        }, [])

    }

    useEffect(() => {
        let timer
        timer = setInterval(() => {
            setActiveTab((currentTab) => {
                const currentIndex = tabHeaders.findIndex(h => h.index == currentTab)
                if(currentIndex === tabHeaders.length - 1){
                    return tabHeaders[0].index
                }
                else{
                    return tabHeaders[currentIndex + 1].index
                }
            })
        }, 5000)
        return () => clearInterval(timer)
    }, [])

    const tabArray = [
        { main: "31,000+ SMEs", sub: 'accessing payment service, loans, savings and Agency banking products', index: '1' },
        { main: "5.6 Million+ households", sub: 'accessing banking through our Agent network', index: '2' },
        { main: "16 Corporates", sub: 'in Sub-Sahara Africa leveraging our Agent network and merchant digitization assets', index: '3' },
        { main: "$52M yearly", sub: 'in Government collections & Payments for 2019/2020', index: '4' },
    ]
    return (
        <Container fluid className="text-center text-md-left my-5">
            <div className="card">
                <div className="card-body px-5">
                    <Row>
                        <Col sm="12">
                            <div className="mt-5 text-center">
                                <h4 className="order-md-4 text-deep-blue"> Our Track Record</h4>
                                <div>
                                    <img loading='lazy' alt=' dashed underline' width='70' src={require('../../../assets/svgs/Underline-What-We0Do.svg').default} className="pb-3 w-10"/> 
                                </div>
                            </div>
                        </Col>
                        <Col md="6" className="order-2 order-md-1 mt-2">
                            <img loading='lazy' src={require('../../../assets/images/track-record-image.png').default} className="img-fluid " />

                        </Col>
                        <Col md="6" className="order-1 order-md-2">
                            <p className="text-deep-blue h4 my-4">
                                Here’s how we performed from <span className="font-weight-bolder">2019</span> to <br />  <span className="font-weight-bolder">2020</span>
                            </p>
                            <div className="mt-4 d-none d-md-flex">
                                <Nav tabs className="mr-2">
                                    {tabHeaders.map((content, i) => (
                                        <NavItem
                                            key={content.index}
                                        >
                                            <NavLink
                                                className={classnames({ active: activeTab === content.index }, 'nav-link')}
                                                onClick={() => { toggle(content.index); }}
                                            >
                                                {content.header}
                                                <hr className="styled-hr m-0" />
                                            </NavLink>
                                        </NavItem>
                                    ))}

                                </Nav>
                                <TabContent activeTab={activeTab} className="w-75">
                                    {tabArray.map((content, i) => (
                                        <TabPane tabId={content.index} key={content.index}>
                                            <Row>
                                                <Col sm="12">
                                                    <div className="track-card">
                                                        <h6>{content.main}</h6>
                                                        <p className="small">{content.sub}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    ))}
                                </TabContent>
                            </div>
                            <div className="mt-4 d-block d-md-none">
                                <Row>

                                    {tabArray.map((content, i) => (
                                        <Col sm="12" key={content.index} className="my-2">
                                            <div className="track-card">
                                                <h6>{content.main}</h6>
                                                <p className="small">{content.sub}</p>
                                            </div>
                                        </Col>

                                    ))}
                                </Row>

                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5 pb-3 px-4'>
                        <Col>
                            <h5 className='mt-3 text-deep-blue font-weight-bolder'>We are partners with global Banking and Financial Technology brands </h5>
                        </Col>
                        {/* <Col lg='12' className='d-flex justify-content-between flex-wrap'>
                        <div sm='4' ><img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/Visa.png').default} /></div>
                        <div sm='4'><img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/Mastercard.png').default} /></div>
                        <div sm='4'><img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/Fidelity.png').default} /></div>
                        <div sm='4'><img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/GT.png').default} /></div>
                        <div sm='4'><img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/Access.png').default} /></div>
                        <div sm='4'><img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/Visa.png').default} /></div>
                    </Col> */}
                    </Row>
                    <div className="mb-5">
                        <CarouselSlide
                            additionalTransfrom={0}
                            arrows={true}
                            autoPlay={true}
                            // containerClass="w-md-100 h-100"
                            draggable
                            infinite={true}
                            focusOnSelect={false}
                            keyBoardControl={false}
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 5,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 3,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 4,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            <img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/Visa.png').default} />
                            <img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/Mastercard.png').default} />
                            <img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/Fidelity.png').default} />
                            <img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/GT.png').default} />
                            <img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/Access.png').default} />
                            <img className='img-fluid mx-md-0 mx-3 align-middle' src={require('../../../assets/svgs/BPC-logo.svg').default} width='120' />
                            <img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/unionpay.png').default} />
                            <img className='img-fluid mx-md-0 mx-3' src={require('../../../assets/images/uba logo.png').default} />
                        </CarouselSlide>
                    </div>
                </div>
            </div>
        </Container>
    )
}
