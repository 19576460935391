import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

export default function EasyCommerce() {
    return(
        <Container className='text-center text-md-left my-5'>
            <Row>
                <Col md="6" className="pt-5 mb-5">
                    <div className='pr-md-5'>
                        <img loading='lazy' alt='easy commerce logo' src={require('../../../assets/svgs/EasyCommerceLogo.svg').default} className='mb-5'/>
                        <p>Internet Payment Gateway and WebStore enabling businesses to sell and accept payments online. </p>
                        <p>EasyCommerce offers best in class transaction security, responsive APIs and robust transaction processing capabilities.</p>
                        <Button outline color='primary'>Learn More</Button>
                    </div>
                </Col>
                <Col md="6">
                    <img loading='lazy' alt="background for easy commerce" className='img-fluid' src={require('../../../assets/images/EasyCommerce-BG.png').default} />
                </Col>
            </Row>
        </Container>
    )
}