import React from 'react'
import Header from '../../components/common/Header'
import Locations from './SubComponents/Locations'
import Available from './SubComponents/Available'
import ContactComponent from './SubComponents/Contact'
import Footer from '../../components/common/Footer'

export default function Contact() {
    return (
        <>
        <Header />
        <div id="locations">
            <Locations />
        </div>
        <div id="available">
            <Available />
        </div>

        <div id="contact">
            <ContactComponent />
        </div>


        <div id="footer">
            <Footer />
        </div>
        </>
    )
}
