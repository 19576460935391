import React, { useState } from 'react'
import { Container, Row, Col, Input, Button } from 'reactstrap'
import FormsWrapper, { showFieldError } from '../../../components/common/Formik'
import * as Yup from "yup"
import { send } from 'emailjs-com'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const validation = Yup.object().shape({
    yourName: Yup.string().required("Required"),
    email: Yup.string().email()
        .required("Required"),
    message: Yup.string()
        .required("Required"),
})

export default function Contact() {
    const [formValues, setFormValues] = useState({
        yourName: '',
        email: '',
        message: '',
    })

    const onChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (formikValues, { resetForm, ...formikProps }) => {
        try {
            let submit = await send(
                `${process.env.REACT_APP_EMAILJS_SERVICEID}`,
                `${process.env.REACT_APP_EMAILJS_TEMPLATEID}`,
                {
                    email: formValues.email,
                    message: formValues.message,
                    yourName: formValues.yourName
                },
                `${process.env.REACT_APP_EMAILJS_USERID}`
            )
            resetForm()
            setFormValues({
                yourName: '',
                email: '',
                message: '',
            })
            toast.success('Your Message has been received. We will Get back to you Shortly')
        } catch (error) {
            toast.error('An error occured, Try Again')

        }

    }

    return (
        <Container className="my-5">
            <Row>
                <Col sm="12">
                    <h4 className="text-deep-blue text-center my-5">Leave us a message</h4>
                </Col>
                <Col lg="4" md="6" className="mx-auto">
                    <FormsWrapper
                        values={formValues}
                        handleSubmit={handleSubmit}
                        handleChange={onChange}
                        validationSchema={validation}>
                        {
                            props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    isSubmitting,
                                    handleSubmit } = props;
                                return (
                                    <form onSubmit={handleSubmit} onChange={onChange}>
                                        <div className="form-group">
                                            <label>Your Name</label>
                                            <Input
                                                name="yourName"
                                                type="text"
                                                value={values.yourName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={errors.yourName && touched.yourName}
                                            />
                                            {showFieldError("yourName", errors, touched)}
                                        </div>
                                        <div className="form-group">
                                            <label>Your Email Address</label>
                                            <Input
                                                name="email"
                                                type="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={errors.email && touched.email}
                                            />
                                            {showFieldError("email", errors, touched)}
                                        </div>

                                        <div className="form-group">
                                            <label>Your Message</label>

                                            <Input
                                                name="message"
                                                type="textarea"
                                                value={values.message}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={errors.message && touched.message}
                                            />
                                            {showFieldError("message", errors, touched)}
                                        </div>
                                        <div className="form-group text-center">
                                            <Button color="primary" disabled={isSubmitting} className="p-2 mb-5" type="submit">
                                                {isSubmitting ? 'Sending...' : 'Send Message'}
                                            </Button>

                                        </div>

                                    </form>
                                )
                            }
                        }
                    </FormsWrapper >
                </Col>
            </Row>
        </Container>
    )
}
