import React from 'react'
import {Link} from "react-router-dom"
import { Container, Row, Col,  } from 'reactstrap'
import Logo from './Logo'

function Footer(){
    return(
        <Container>
            <Row className='my-3 border-bottom'>
                <Col md="3" >
                    <Logo width="150" />
                </Col>
                <Col md="3">
                    <h5 className='text-deep-blue font-weight-bolder'>Company</h5>
                    <ul className='lists'>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        <li>Team</li>
                        <li><a href={`${process.env.REACT_APP_BASE_URL}`}>Blog</a></li>
                        <li>Press</li>
                    
                    </ul>
                </Col>
               
                <Col md="3" className=''>
                    <h5 className='text-deep-blue font-weight-bolder'>Solutions</h5>
                    <ul className='lists'>
                        <li>Agency Banking Platform</li>
                        <li>Digital Banking APIs</li>
                        <li>Internet Payment Gateway </li>
                        <li>Agent Distribution Network</li>
                        <li>Payment Processing Switch</li>
                    </ul>
                </Col>
                
                <Col md="3">
                    <h5 className='text-deep-blue font-weight-bolder'>We’re Social</h5>
                    <ul className='lists'>
                        <li>Facebook</li>
                        <li>Twitter</li>
                        <li>Instagram</li>
                        <li>LinkedIn</li>
                        <li>Whatsapp</li>
                    </ul>
                </Col>
            </Row>
            <Row className=' my-3 border-bottom'>
                <Col className='d-flex flex-row justify-content-end'>
                    <ul className='lists d-flex '>
                        <li className='px-3'>Terms</li>
                        <li className='px-3'>Privacy</li>
                        <li className='px-3'>Cookies</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <p>Innovectives Digital Experience (inDex) Group &copy; {new Date().getFullYear()}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer
