import React from 'react'
import Header from '../../components/common/Header'
import Inclusive from './SubComponents/Inclusive'
import Easyfinance from './SubComponents/Easyfinance'
import EasyWallet from './SubComponents/EasyWallet'
import TrackRecord from './SubComponents/TrackRecord'
import EasyCommerce from './SubComponents/EasyCommerce'
import EasySwitch from './SubComponents/EasySwitch'
import EasyBox from './SubComponents/Easybox'
import Expanding from './SubComponents/Expanding'
import OurBlog from './SubComponents/Blog'
import Footer from '../../components/common/Footer'

export default function Landing() {
    return (
        <div>
            <Header />
            <div id="inclusive">
                <Inclusive />
            </div>
            <div className="mt-5 text-center">
                <h4 className="order-md-4 text-deep-blue"> Our Solutions</h4>
                <div>
                    <img loading='lazy' alt=' dashed underline' width='70' src={require('../../assets/svgs/Underline-What-We0Do.svg').default} className="pb-3 w-10"/> 
                </div>
            </div>
            <div id="easycommerce" className="py-5">
                <EasyCommerce />
            </div>
            <div id="easyswitch" className='py-5 bg-light-blue'>
                <EasySwitch />
            </div>
            <div id="easybox" className='py-5 bg-light-gray'>
                <EasyBox />
            </div>
            <div id="easyfinance" className="py-5">
                <Easyfinance />
            </div>
            <div className="bg-light-blue">
                <div id="easywallet" className="">
                    <EasyWallet />
                </div>
                <div id="trackrecord" className="py-5">
                    <TrackRecord />
                </div>

                <div id="expanding" className="py-5">
                    <Expanding />
                </div>
            </div>
            <div id='blog' className='bg-white py-5'>
                <OurBlog />
            </div>
            <div className='bg-light-gray py-4' id='footer'>
                <Footer />
            </div>
        </div>
    )
}
