import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { getPosts } from '../../../services/postsService'
import { statements } from '@babel/template'
import { getMedia } from '../../../services/mediaService'



function GetDefaultImg({id}) {
    let [img, setImg] = useState({url: '', loading: true})

    const getMediaImg = async()=>{
        try {
            const {data} = await getMedia(id)
            setImg({url: data.source_url, loading: false})
        } catch (error) {
            
        }
    }
    useEffect(() => {
        getMediaImg()
    }, [])
    return <img loading="lazy" className='w-100 h-100' src={img.url || require('../../../assets/images/Digital Banking and  Card Issuance APIs.png').default} />
}


function OurBlog(){
    const [state, setState] = useState({
        posts: [],
        loading: true
    })

    useEffect(() => {
        getBlogs()
    }, [])

    const getBlogs = async()=>{
        try {
            let {data} = await getPosts(4, 1)
            setState({
                posts: data,
                loading: false
            })
        } catch (error) {
            setState({
                ...state,
                loading: false
            })
        }
    }
    return(
        <Container className='my-5'>
            <Row>
                <Col sm='12'>
                    <div className="mt-5 text-center">
                        <h4 className="order-md-4 text-deep-blue">Our Blog</h4>
                        <div>
                            <img loading='lazy' alt=' dashed underline' width='70' src={require('../../../assets/svgs/Underline-What-We0Do.svg').default} className="pb-3 w-10"/> 
                        </div>
                    </div>
                </Col>
                {state.posts.map((post)=>(
                <Col md='3' className='my-4'>
                    <div className='position-relative h-100'>
                        {/* <img className='img-fluid' alt='index network' src={require('../../../assets/images/Agency Banking Platform.png').default} /> */}
                        <GetDefaultImg 
                            id={post.featured_media}
                        />
                        <div className='bottom'>
                            <p>{post.title.rendered}</p>
                            {/* <a className='readmore' href={post.link} target="blank"> Read More</a> 
                            <hr className="styled-hr" /> */}
                        </div>
                        
                    </div>
                </Col>

                ))}
                <Col sm="12" className='text-center my-4'>
                    <a href={`${process.env.REACT_APP_BASE_URL}`} target="blank" className='btn btn-primary'> Get More Stories</a>
                </Col>
            </Row>
        </Container>
    )
}

export default OurBlog
