import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

function EasyBox() {
    return(
        <Container className='text-center text-md-left pt-5'>
            <Row>
                <Col md="6" className="pt-5 mb-5">
                    <div className='pr-md-5'>
                        <div className="pr-3">
                            <img loading='lazy' alt='easy box logo' src={require('../../../assets/svgs/EasyBox.svg').default} className='mb-5'/>
                            <p>
                                {/* Mobile-enabled distribution network, and agency banking, connecting underserved communities with access to better banking and commerce.   */}
                                White-label Agent Platform, connecting underserved communities with better banking and commerce
                                </p>
                            <p>Services offered includes: Card activation, deposit, cash-out, micro-credit, retail solar, and much more</p>
                            <a href="https://www.merchants.ng/" className="btn btn-outline-primary" target="blank">Learn More</a>
                        </div>
                    </div>
                </Col>
                <Col md="6">
                <div className="pl-3">

                    <img loading='lazy' alt="background for easy box" className='img-fluid' src={require('../../../assets/images/easy-wallet-img.png').default} />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default EasyBox
