import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

export default function EasyWallet() {
    return (
        <Container className="text-center text-md-left">
            <Row>
                <Col md="6" className="pt-5 mb-5 pl-n5">
                    <div className='pr-md-5'>
                        <img loading='lazy' src={require("../../../assets/svgs/EasyWallet.svg").default} className="mb-5" />
                        <p className="mb-3">EasyWallet is a white-label FinTech API Suite powering embedded finance in frontier markets.</p>
                        <p className="mt-0">
                        EasyWallet features APIs for Digital KYC, mobile banking, card issuance, remittance, credit scoring and payment terminal activation
                        </p>
                        <Button outline color="primary">
                        Learn More
                        </Button>
                    </div>
                    
                </Col>
                <Col md="6">
                <img loading='lazy' src={require('../../../assets/svgs/easy-wallet-img.svg').default} className="img-fluid mt-n5" style={{borderRadius:'2rem'}} />

                </Col>
            </Row>
        </Container>
    )
}
