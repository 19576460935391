import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

export default function Easyfinance() {
    return (
        <Container className="my-5 text-center text-md-left">
            <Row>
                <Col md="6" className="order-2 order-md-1">
                    <div className="py-4 pr-md-4">
                        <img loading='lazy' src={require('../../../assets/images/easy-finance-img.png').default} className="img-fluid" />
                    </div>

                </Col>
                <Col md="6" className="order-1 text-deep-blue order-md-2">
                    <div className='pl-md-4'>
                        <div className="py-4 pl-md-4">
                            <img loading='lazy' src={require("../../../assets/svgs/EasyFinance.svg").default} className="mb-5" />
                            <div className=''>
                                <p className="mb-3">Adaptive credit and savings for businesses and individuals delivered through online and offline distribution channels.</p>
                                <p className="mt-0">
                                    EasyFinance is offered across frontier markets through licensed financial institutions
                                </p>
                                <a href="https://www.easyfinance.africa" className="btn btn-outline-primary" target="blank">
                                Learn More
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
