import React from "react";

function WorldContentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 851.91 672"
    >
      <defs>
        <filter
          id="Rectangle_1611"
          width="440"
          height="192"
          x="0"
          y="480"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="8"></feOffset>
          <feGaussianBlur result="blur" stdDeviation="8"></feGaussianBlur>
          <feFlood floodOpacity="0.039"></feFlood>
          <feComposite in2="blur" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
        <filter
          id="Rectangle_1611-2"
          width="440"
          height="215"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="8"></feOffset>
          <feGaussianBlur result="blur-2" stdDeviation="8"></feGaussianBlur>
          <feFlood floodOpacity="0.039"></feFlood>
          <feComposite in2="blur-2" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
        <filter
          id="Rectangle_1611-3"
          width="440"
          height="215"
          x="0"
          y="240"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="8"></feOffset>
          <feGaussianBlur result="blur-3" stdDeviation="8"></feGaussianBlur>
          <feFlood floodOpacity="0.039"></feFlood>
          <feComposite in2="blur-3" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g transform="translate(-167 -6002)">
        <g data-name="New York" transform="translate(-925.5 5603)">
          <g filter="url(#Rectangle_1611)" transform="translate(1092.5 399)">
            <rect
              width="392"
              height="144"
              fill="#fff"
              data-name="Rectangle 1611"
              rx="16"
              transform="translate(24 496)"
            ></rect>
          </g>
          <text
            fill="#0a194e"
            data-name="New York"
            fontFamily="Nunito-Bold, Nunito"
            fontSize="17"
            fontWeight="700"
            transform="translate(1156.5 952)"
          >
            <tspan x="0" y="0">
              New York
            </tspan>
          </text>
          <text
            fill="#0a194e"
            data-name="25 Bond Street, Brooklyn, NY 11201"
            fontFamily="Nunito-Regular, Nunito"
            fontSize="17"
            transform="translate(1156.5 976)"
          >
            <tspan x="0" y="17">
              25 Bond Street, Brooklyn, NY 11201
            </tspan>
          </text>
        </g>
        <g transform="translate(-925.5 5123)">
          <g filter="url(#Rectangle_1611-2)" transform="translate(1092.5 879)">
            <rect
              width="392"
              height="167"
              fill="#fff"
              data-name="Rectangle 1611"
              rx="16"
              transform="translate(24 16)"
            ></rect>
          </g>
          <text
            fill="#0a194e"
            data-name="Lagos"
            fontFamily="Nunito-Bold, Nunito"
            fontSize="17"
            fontWeight="700"
            transform="translate(1156.5 952)"
          >
            <tspan x="0" y="0">
              Lagos
            </tspan>
          </text>
          <text
            fill="#0a194e"
            data-name="14, Yeye Olofin Street, Lekki Phase 1, Lagos, Nigeria"
            fontFamily="Nunito-Regular, Nunito"
            fontSize="17"
            transform="translate(1156.5 976)"
          >
            <tspan x="0" y="17">
              14, Yeye Olofin Street, Lekki Phase 1,{" "}
            </tspan>
            <tspan x="0" y="40">
              Lagos, Nigeria
            </tspan>
          </text>
        </g>
        <g transform="translate(-925.5 5363)">
          <g filter="url(#Rectangle_1611-3)" transform="translate(1092.5 639)">
            <rect
              width="392"
              height="167"
              fill="#fff"
              data-name="Rectangle 1611"
              rx="16"
              transform="translate(24 256)"
            ></rect>
          </g>
          <text
            fill="#0a194e"
            data-name="Amsterdam"
            fontFamily="Nunito-Bold, Nunito"
            fontSize="17"
            fontWeight="700"
            transform="translate(1156.5 952)"
          >
            <tspan x="0" y="0">
              Amsterdam
            </tspan>
          </text>
          <text
            fill="#0a194e"
            data-name="Soerlaan 18, 1185 JJ Amstelveen, Netherlands"
            fontFamily="Nunito-Regular, Nunito"
            fontSize="17"
            transform="translate(1156.5 976)"
          >
            <tspan x="0" y="17">
              Soerlaan 18, 1185 JJ Amstelveen,{" "}
            </tspan>
            <tspan x="0" y="40">
              Netherlands
            </tspan>
          </text>
        </g>
        <g transform="translate(689.969 6182)">
          <circle
            cx="158.556"
            cy="158.556"
            r="158.556"
            fill="#71c2f5"
            data-name="Ellipse 166"
            transform="translate(11.829 13.001)"
          ></circle>
          <path
            fill="#56ace1"
            d="M198.076 331.912a158.566 158.566 0 01-88.315-290.254A158.578 158.578 0 10263 318.05a158 158 0 01-64.924 13.862z"
            data-name="Path 1494"
            transform="translate(-4.297 -14.799)"
          ></path>
          <g
            fill="#15b259"
            data-name="Group 1402"
            transform="translate(48.803 26.888)"
          >
            <path
              d="M185.968 470.629L162.979 483.8a158.724 158.724 0 00152.17-13.446 158.932 158.932 0 01-129.181.273z"
              data-name="Path 1495"
              transform="translate(-105.27 -193.985)"
            ></path>
            <path
              d="M130.089 41.7a159.09 159.09 0 00-56.617 42.9l8.328 8.331A159.346 159.346 0 01130.089 41.7z"
              data-name="Path 1496"
              transform="translate(-73.472 -41.703)"
            ></path>
          </g>
          <path
            fill="#3ac274"
            d="M336.828 258.252l-84.334-20.079s-13.213-39.639-13.213-46.246 33.032-46.246 33.032-46.246l80.387-8.462A158.652 158.652 0 00134.676 34.048a159.364 159.364 0 00-48.291 51.227l20.767 20.767L160 112.648l39.639 33.26 6.607 39.411 6.607 39.639-24.146 68.572-29.95 17.162a158.933 158.933 0 00129.182-.273 159.39 159.39 0 0048.889-52.167z"
            data-name="Path 1497"
            transform="translate(-29.257 -7.161)"
          ></path>
          <path
            fill="#15b259"
            d="M39.519 240.161c0-3.514.127-7 .351-10.454l-22.27 1.857a158.647 158.647 0 0063.676 149.717l20.131-15.443a158.285 158.285 0 01-61.888-125.677z"
            data-name="Path 1498"
            transform="translate(-4.296 -81.605)"
          ></path>
          <path
            fill="#3ac274"
            d="M133.4 348.412l13.231-40.093a5.2 5.2 0 00-2.262-6.087l-28.06-16.832a5.2 5.2 0 01-2.076-2.346l-25.759-57.962-35.714 2.976c-.224 3.457-.351 6.94-.351 10.454A158.285 158.285 0 00114.3 364.2l17.327-13.291a5.208 5.208 0 001.773-2.497z"
            data-name="Path 1499"
            transform="translate(-17.187 -79.965)"
          ></path>
          <path
            fill="#ffb03c"
            d="M365.275 0a46.84 46.84 0 00-46.787 46.787c0 32.965 41.351 74.7 43.111 76.456a5.25 5.25 0 007.352 0c1.76-1.76 43.111-43.491 43.111-76.456A46.839 46.839 0 00365.275 0z"
            data-name="Path 1503"
            transform="translate(-111.713)"
          ></path>
          <path
            fill="#ffe88f"
            d="M376.733 32.252a25.993 25.993 0 1025.993 25.993 26.022 26.022 0 00-25.993-25.993z"
            data-name="Path 1505"
            transform="translate(-123.17 -11.458)"
          ></path>
          <g data-name="Group 1404" transform="translate(0 32.419)">
            <path
              fill="#ffb03c"
              d="M38.691 104.819a34.7 34.7 0 00-34.66 34.66c0 24.421 30.633 55.335 31.937 56.638a3.888 3.888 0 005.446 0c1.3-1.3 31.937-32.218 31.937-56.638a34.7 34.7 0 00-34.66-34.66z"
              data-name="Path 1506"
              transform="translate(-3.467 -12.238)"
            ></path>
            <path
              fill="#ffe88f"
              d="M55.539 137.071a19.256 19.256 0 1019.256 19.256 19.277 19.277 0 00-19.256-19.256z"
              data-name="Path 1508"
              transform="translate(-20.315 -29.086)"
            ></path>
          </g>
          <g data-name="Group 1405" transform="translate(200.037 132)">
            <path
              fill="#ffb03c"
              d="M38.691 104.819a34.7 34.7 0 00-34.66 34.66c0 24.421 30.633 55.335 31.937 56.638a3.888 3.888 0 005.446 0c1.3-1.3 31.937-32.218 31.937-56.638a34.7 34.7 0 00-34.66-34.66z"
              data-name="Path 1506"
              transform="translate(-3.467 -12.238)"
            ></path>
            <path
              fill="#ffe88f"
              d="M55.539 137.071a19.256 19.256 0 1019.256 19.256 19.277 19.277 0 00-19.256-19.256z"
              data-name="Path 1508"
              transform="translate(-20.315 -29.086)"
            ></path>
          </g>
        </g>
        <g
          fill="none"
          stroke="#aab7c3"
          strokeDasharray="8"
          strokeLinecap="round"
          strokeWidth="1"
          transform="translate(583 6501.346)"
        >
          <path
            d="M0 0L0 85.154"
            data-name="Line 542"
            transform="translate(342.441)"
          ></path>
          <path
            d="M0 0L341.941 0"
            data-name="Line 543"
            transform="translate(0 85.654)"
          ></path>
        </g>
        <g
          fill="none"
          stroke="#aab7c3"
          strokeDasharray="8"
          strokeLinecap="round"
          strokeWidth="1"
          transform="translate(583 6075)"
        >
          <path
            d="M0 106.461L0 0"
            data-name="Line 542"
            transform="translate(360 .539)"
          ></path>
          <path d="M0 0L359.615 0" data-name="Line 543"></path>
        </g>
        <g transform="rotate(-90 3465 2882)">
          <path
            fill="none"
            stroke="#aab7c3"
            strokeDasharray="8"
            strokeLinecap="round"
            strokeWidth="1"
            d="M0 0L0 107.533"
            data-name="Line 542"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default WorldContentIcon;
