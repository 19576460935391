import React from 'react'
import {Link} from "react-router-dom"
import { Container, Row, Col, Button } from 'reactstrap'

export default function Inclusive() {
    return (
        <Container>
                <Row>
                    <Col md="7"  className="mt-5">
                        <h2 className=" mt-md-5 main-header">
                        {/* Inclusive Banking and Payment Platform for frontier markets. */}
                        {/* Inclusive Banking, Payment {"&"} Commerce Platform for frontier Markets */}
                        Digital Banking and Payment Platform for frontier Markets
                        </h2>

                        <p className=" mt-4 font-weight-lighter">
                        {/* Innovectives Digital Experience <span className="font-weight-bolder">(inDex)</span> provides easy-to-use solutions - to make banking and commerce accessible */}
                        </p>

                        <a href="/contact" className="btn btn-lg btn-primary mt-4 px-5 py-3">
                            Contact Us
                        </a>
                    </Col>
                </Row>
        </Container>
    )
}
