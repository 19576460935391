import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { ToastContainer,toast } from 'react-toastify';
import Landing from "../pages/Landing";
import ScrollToTop from "../components/common/ScrollToTop";
import Contact from "../pages/Contact";


const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route path="/contact" component={Contact} />
        <Route path="/" component={Landing} />
        <Redirect from="*" to="/"/>
      </Switch>
      <ToastContainer />
    </ScrollToTop>
  </Router>
);

export default Routes;
