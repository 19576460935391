import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { EmailIcon, FacebookIcon, InstagramIcon, WhatsappIcon, PhoneIcon } from '../../../components/common/Icons'

export default function Available() {

    const socialArr = [
        {
            Icon: PhoneIcon,
            main: "Call us",
            sub: '+01 290 4554'
        },
        {
            Icon: FacebookIcon,
            main: "Facebook",
            sub: 'Innovectives Group'
        },
        {
            Icon: InstagramIcon,
            main: "Instagram",
            sub: '@innovectives.group'
        },
        {
            Icon: EmailIcon,
            main: "Send Email",
            sub: 'info@innovectives.com'
        },
        {
            Icon: WhatsappIcon,
            main: "WhatsApp",
            sub: '0808 111 2223'
        }

    ]
    return (
        <Container className="my-5">
            <Row>
                <Col sm="12" className="text-center">
                    <h4 className="text-deep-blue">We’re always available</h4>
                </Col>
            </Row>
            <Row>
                {socialArr.map(({Icon, main, sub}, i)=>(
                    <Col key={i} className="text-center">
                    <div className="social-icons">
                        <Icon />
                        <small className="d-block">{main}</small>
                        <small className="d-block">{sub}</small>
                    </div>
                </Col>
                ))}
                
            </Row>
        </Container>
    )
}
